import { cva } from 'cva';
import React, { ReactNode } from 'react';

import { EFormSlotV2Theme } from './constants';
import {
  ETitlePartsPosition,
  ETitlePartsSize,
  TitlePart,
} from '../../atoms/TitlePart';
import { ETitlePartsTheme } from '../../atoms/TitlePart/TitlePart.constants';

export interface IFormSlotV2Props {
  title: string;
  children: ReactNode;
  description?: React.JSX.Element | null;
  theme?: EFormSlotV2Theme;
}

const descriptionCVA = cva(
  'font-normal tablet:leading-loose leading-mega-loose text-lg tablet:text-3xl max-w-[25rem]',
  {
    variants: {
      theme: {
        [EFormSlotV2Theme.Light]: 'text-interface-50',
        [EFormSlotV2Theme.Dark]: 'text-interface-1000',
      },
    },
  },
);

const FormSlotV2: React.FC<IFormSlotV2Props> = props => {
  const { description, children, title, theme = EFormSlotV2Theme.Dark } = props;

  return (
    <div className='m-auto flex w-full max-w-[77rem] flex-col justify-between gap-8 px-4 py-10 tablet:flex-row tablet:gap-4 tablet:py-18'>
      <div className='flex w-full flex-col items-start justify-between gap-6'>
        <TitlePart
          size={ETitlePartsSize.XxxxL}
          tag='h3'
          position={ETitlePartsPosition.Left}
          theme={
            theme === EFormSlotV2Theme.Dark
              ? ETitlePartsTheme.Dark
              : ETitlePartsTheme.Light
          }
          className='text-5xl leading-relaxed tablet:text-8xl'
        >
          {title}
        </TitlePart>
        <div className={descriptionCVA({ theme })}>{description}</div>
      </div>
      <div className='w-full'>{children}</div>
    </div>
  );
};

export default FormSlotV2;

import {
  Button,
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
  EFormSlotV2Theme,
  ERichTextBullets,
  FormSlotV2,
  IconCheck,
  RichText,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useFormSubmission } from '../../../features/forms/useFormSubmission.hook';
import { FormInputParserV2 } from '../../sections/FormInputParserV2';

export type TFormSlotConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'form-slot-v2' }
>;

const FormSlotV2Connected: React.FC<TFormSlotConnectedProps> = props => {
  const { form, title, description, theme, hasBorder, buttonColor } = props;
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const confirmationTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(
    null,
  );

  const onSubmitSuccess = useCallback(() => {
    setShowConfirmationMessage(true);

    confirmationTimeoutRef.current = setTimeout(() => {
      setShowConfirmationMessage(false);
    }, 5000);
  }, []);

  const { register, formState, control, handleSubmit, clearErrors } =
    useFormSubmission({
      form,
      onSubmitSuccess,
    });

  useEffect(() => {
    return () => {
      if (confirmationTimeoutRef.current) {
        clearTimeout(confirmationTimeoutRef.current);
      }
    };
  }, []);

  return (
    <FormSlotV2
      title={title}
      description={
        <RichText
          content={description}
          bulletsOptions={{
            bullets: ERichTextBullets.Decimal,
          }}
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className={clsx(
            'prose-ol:gap-6 desktop:prose-ol:gap-8 prose-ol:flex prose-ol:flex-col prose-ol:ps-5 desktop:prose-ol:ps-6 prose-ol:text-base desktop:prose-ol:text-lg list-inside marker:text-lg desktop:marker:text-3xl',
            {
              'text-[rgba(255,242,242,0.75)] marker:text-[rgba(255,242,242,0.5)]':
                theme === EFormSlotV2Theme.Light,
              'text-[rgba(0,0,0,0.50)] marker:text-[rgba(0,0,0,0.25)]':
                theme === EFormSlotV2Theme.Dark,
            },
          )}
        />
      }
      theme={theme as EFormSlotV2Theme}
    >
      {form && typeof form !== 'string' && (
        <>
          <form className='flex flex-col' onSubmit={handleSubmit}>
            <div className='flex flex-col gap-2 tablet:gap-4'>
              {form.fields?.map(field => (
                <FormInputParserV2
                  key={field.name}
                  field={field}
                  control={control}
                  register={register}
                  error={formState.errors[field.name]?.message?.toString()}
                  hasBorder={hasBorder}
                  clearErrors={clearErrors}
                />
              ))}
            </div>
            <div
              className={clsx(
                'flex items-center justify-center py-6  text-center text-base leading-mega-loose tablet:text-lg tablet:leading-relaxed',
                {
                  'prose-p:text-[rgba(255,242,242,0.5)] prose-a:text-[rgba(255,242,242,0.75)] text-[rgba(255,242,242,0.5)]':
                    theme === EFormSlotV2Theme.Light,
                  'prose-p:text-black/25 prose-a:text-black/50  text-black/25':
                    theme === EFormSlotV2Theme.Dark,
                },
              )}
            >
              <RichText content={form.description} />
            </div>
            <div className='grid lg:col-start-1 lg:col-end-3'>
              <Button
                theme={EButtonTheme.Primary}
                size={EButtonSize.Large}
                variant={EButtonVariant.Contained}
                type='submit'
                disabled={!formState.isValid || formState.isSubmitting}
                className='h-16 rounded-lg px-10 py-[1.8125rem]	text-3xl font-semibold leading-loose disabled:opacity-10 tablet:h-[4.5rem] desktop:h-[5.5rem]'
                style={buttonColor ? { backgroundColor: buttonColor } : {}}
              >
                {form.submitButtonLabel || 'Submit'}
              </Button>
            </div>
          </form>
          {form.confirmationType === 'message' && showConfirmationMessage && (
            <div className='fixed inset-x-0 bottom-10 z-10 mx-auto flex max-w-md animate-translate-up items-center gap-4 rounded-2xl bg-surface-50/30 px-6 py-5 text-sm leading-loose backdrop-blur-md'>
              <IconCheck className='aspect-square h-5 w-5 text-success' />
              <RichText content={form.confirmationMessage} />
            </div>
          )}
        </>
      )}
    </FormSlotV2>
  );
};

export default FormSlotV2Connected;
